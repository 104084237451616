import { render, staticRenderFns } from "./ArticleTestHotelNewView.vue?vue&type=template&id=7246a215&scoped=true&"
import script from "./ArticleTestHotelNewView.vue?vue&type=script&lang=js&"
export * from "./ArticleTestHotelNewView.vue?vue&type=script&lang=js&"
import style0 from "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./ArticleTestHotelNewView.vue?vue&type=style&index=1&id=7246a215&scoped=true&lang=scss&"
import style2 from "./ArticleTestHotelNewView.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7246a215",
  null
  
)

export default component.exports