<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <template v-if="display">
                <button
                  type="button"
                  class="btn btn-success"
                  :disabled="saving"
                  @click="save"
                >
                  <i class="fa fa-save "></i> {{ $t('car.save') }}
                </button>
                <app-button-delete-with-usages
                  v-if="mode === 'update'"
                  @deleteRecord="deleteHotel"
                  @load-data="checkUsages"
                  @page-change="setUsagesPageAndGetRecords"
                  :data="usages.data"
                  :total-count="usages.totalCount"
                  :page="usages.page"
                  :showLoader="usages.showLoader"
                  :config="usagesDataTableConfig"
                  :showDeleteButton="isAllowedToDelete"
                ></app-button-delete-with-usages>
              </template>
              <app-button-close route-name="hotel_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <section v-if="display" class="content form-horizontal">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-body">
              <input type="hidden" v-model="hotel.id">
              <app-input
                v-model="hotel.title"
                @blur="$v.hotel.title.$touch()"
                :error="$v.hotel.title.$error"
                id="hotel_title"
                :label="$t('hotel.title')"
                :required="true"
              >
              </app-input>
              <app-textarea
                v-model="hotel.description"
                id="hotel_description"
                :label="$t('hotel.description')"
                :maxlength="1024"
                @blur="$v.hotel.description.$touch()"
                :error="$v.hotel.description.$error"
              >
              </app-textarea>
              <app-input
                v-model="hotel.name"
                @blur="$v.hotel.name.$touch()"
                :error="$v.hotel.name.$error"
                id="hotel_name"
                :label="$t('hotel.name')"
                :required="true"
              >
              </app-input>
              <app-input
                v-model="hotel.yearOfReview"
                @blur="$v.hotel.yearOfReview.$touch()"
                :error="$v.hotel.yearOfReview.$error"
                id="hotel_yearOfReview"
                :label="$t('hotel.yearOfReview')"
                :required="true"
                type="number"
                :min="1900"
              >
              </app-input>
              <app-input
                v-model="hotel.phone"
                @blur="$v.hotel.phone.$touch()"
                :error="$v.hotel.phone.$error"
                id="hotel_phone"
                :label="$t('hotel.phone')"
              >
              </app-input>
              <app-input
                v-model="hotel.website"
                @blur="$v.hotel.website.$touch()"
                :error="$v.hotel.website.$error"
                id="hotel_website"
                :label="$t('hotel.website')"
              >
              </app-input>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-body">
              <div class="form-group">
                <label :class="{'error': $v.hotel.address.$error}">{{ $t('hotel.address') }} <span
                  class="required">*</span></label><br>
                <app-address
                  @set-address="setAddress"
                  @delete-address="deleteAddress"
                  :addressId="this.hotel.address"
                >
                </app-address>
              </div>
            </div>
            <div class="card card-body">
              <app-select
                v-model="hotel.hotelStars"
                :options="hotelStarsValues"
                id="hotel_stars"
                :label="$t('hotel.stars')"
                :noEmptyValue="true"
                :required="true"
                optionValue="value"
              >
              </app-select>
              <app-select
                v-model="hotel.priceLevel"
                :options="restaurantHotelPriceLevelValues"
                id="hotel_rating"
                :label="$t('hotel.priceLevel')"
                :noEmptyValue="true"
                :required="true"
                optionValue="value"
              >
              </app-select>
              <app-select
                v-model="hotel.rating"
                :options="restaurantHotelRatingValues"
                id="hotel_rating"
                :label="$t('hotel.rating')"
                :noEmptyValue="true"
                :required="true"
                optionValue="value"
              >
              </app-select>
              <div class="form-group">
                <label>{{ $t('hotel.image') }}</label><br>
                <app-media-select-button
                  @set-media="setImage"
                >
                </app-media-select-button>
                <app-media-upload-button
                  @set-media="setImage"
                >
                </app-media-upload-button>
              </div>
              <div class="form-group">
                <app-media-editable
                  v-if="hotelImage"
                  :media="hotelImage"
                  :width="360"
                  :height="202"
                  @remove-media="removeImage"
                >
                </app-media-editable>
              </div>
              <table class="test-hotel pros-cons">
                <tr>
                  <th colspan="5">
                    <label>
                      <strong>{{ $t('hotel.pros') }}:</strong>
                    </label>
                  </th>
                </tr>
                <tr v-for="(pro, proIndex) in hotel.prosCons.pros" :key="`item-${proIndex}`">
                  <td>
                    <app-advanced-input
                      v-model="hotel.prosCons.pros[proIndex]"
                      showButtons
                      :buttons="inputDeleteButtons"
                      @input-button-clicked="removePro($event, proIndex)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr v-if="showAddProsButton" class="button-align-center">
                  <td>
                    <button class="btn btn-success" @click="addPro()"><i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <th colspan="5">
                    <label>
                      <strong>{{ $t('hotel.cons') }}:</strong>
                    </label>
                  </th>
                </tr>
                <tr v-for="(con, conIndex) in hotel.prosCons.cons" :key="`item-${conIndex}`">
                  <td>
                    <app-advanced-input
                      v-model="hotel.prosCons.cons[conIndex]"
                      showButtons
                      :buttons="inputDeleteButtons"
                      @input-button-clicked="removeCon($event, conIndex)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr v-if="showAddConsButton" class="button-align-center">
                  <td>
                    <button class="btn btn-success" @click="addCon()"><i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
      <div v-else>
        <app-preloader></app-preloader>
      </div>
      <app-preloader-full v-if="saving"></app-preloader-full>
    </template>
  </app-module-view>
</template>

<script>
import { integer, maxLength, maxValue, minLength, minValue, required } from 'vuelidate/lib/validators'
import AdvancedInput from '../../components/form/inputs/InputAdvanced'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import Textarea from '../../components/form/Textarea'
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import PreloaderFull from '../../components/preloader/PreloaderFull'
import ButtonClose from '../../components/shared/ButtonClose'
import ButtonDeleteWithUsages from '../../components/shared/ButtonDeleteWithUsages'
import MediaEditable from '../../components/shared/MediaEditable'
import MediaSelectButton from '../../components/shared/MediaSelectButton'
import Address from '../../components/shared/Address'
import MediaUploadButton from '../../components/shared/MediaUploadButton'
import HotelModel from '../../model/HotelModel'
import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'
import NotifyService from '../../services/NotifyService'
import RestaurantHotelRatingMixin from '../../components/mixins/valueObject/RestaurantHotelRatingMixin'
import RestaurantHotelPriceLevelMixin from '../../components/mixins/valueObject/RestaurantHotelPriceLevelMixin'
import HotelStarsMixin from '../../components/mixins/valueObject/HotelStarsMixin'

const MAX_PROS_CONS = 3

export default {
  name: 'HotelNewView',
  mixins: [RestaurantHotelRatingMixin, RestaurantHotelPriceLevelMixin, HotelStarsMixin],
  data () {
    return {
      dataLoaded: false,
      saving: false,
      hotel: this._.cloneDeep(HotelModel),
      inputDeleteButtons: [{ class: 'btn btn-danger', html: '<i class="fas fa-trash"></i>' }],
      mode: 'create',
      usages: {},
      showAddProsButton: true,
      showAddConsButton: true,
      usagesDataTableConfig: {
        fields: {
          'field.title': this.$t('article.list.title'),
          'setting.status': this.$t('article.list.status'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          copyToClipboard: 'documentId',
          detail: 'article_detail'
        }
      }
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isAllowedToDelete () {
      return this.currentUser.roles.includes(ROLE_SUPER_ADMIN)
    },
    display () {
      return this.mode === 'create' || (this.mode === 'update' && this.dataLoaded)
    },
    hotelImage () {
      return this.$store.getters['hotel/image']
    }
  },
  validations: {
    hotel: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      yearOfReview: {
        integer,
        minValue: minValue(1900),
        maxValue: maxValue(3000)
      },
      description: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      phone: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      website: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      priceLevel: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(5)
      },
      rating: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(100)
      },
      address: {
        required,
        integer,
        minValue: minValue(1)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appAdvancedInput: AdvancedInput,
    appTextarea: Textarea,
    appButtonDeleteWithUsages: ButtonDeleteWithUsages,
    appButtonClose: ButtonClose,
    appAddress: Address,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaEditable: MediaEditable,
    appSelect: Select,
    appPreloader: Preloader,
    appPreloaderFull: PreloaderFull
  },
  methods: {
    addPro () {
      if (this.hotel.prosCons.pros.length < MAX_PROS_CONS) {
        this.hotel.prosCons.pros.push('')
        if (this.hotel.prosCons.pros.length === MAX_PROS_CONS) {
          this.showAddProsButton = false
        }
      }
    },
    addCon () {
      if (this.hotel.prosCons.cons.length < MAX_PROS_CONS) {
        this.hotel.prosCons.cons.push('')
        if (this.hotel.prosCons.cons.length === MAX_PROS_CONS) {
          this.showAddConsButton = false
        }
      }
    },
    removePro (buttonIndex, dataIndex) {
      // we have only one button, so we dont need care of multiple buttons indexes
      this.hotel.prosCons.pros.splice(dataIndex, 1)
      if (this.hotel.prosCons.pros.length < MAX_PROS_CONS) {
        this.showAddProsButton = true
      }
    },
    removeCon (buttonIndex, dataIndex) {
      // we have only one button, so we dont need care of multiple buttons indexes
      this.hotel.prosCons.cons.splice(dataIndex, 1)
      if (this.hotel.prosCons.cons.length < MAX_PROS_CONS) {
        this.showAddConsButton = true
      }
    },
    checkProsConsButtons () {
      if (this.hotel.prosCons.pros.length >= MAX_PROS_CONS) {
        this.showAddProsButton = false
      }
      if (this.hotel.prosCons.cons.length >= MAX_PROS_CONS) {
        this.showAddConsButton = false
      }
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('hotel/storeImage', media)
      })
    },
    setAddress (address) {
      this.hotel.address = address.id
      this.hotel.expanded.address = address
    },
    deleteAddress () {
      this.hotel.address = null
      this.hotel.expanded.address.id = null
      this.save()
    },
    removeImage () {
      this.$store.commit('hotel/storeImage', null)
    },
    prepareRequest (hotel) {
      const hotelRequest = this._.cloneDeep(hotel)
      hotelRequest.image = null
      if (this.$store.getters['hotel/image']) {
        hotelRequest.image = this.$store.getters['hotel/image'].id
      }
      hotelRequest.prosCons.pros = hotelRequest.prosCons.pros.filter(pro => pro.trim().length > 0)
      hotelRequest.prosCons.cons = hotelRequest.prosCons.cons.filter(con => con.trim().length > 0)
      if (this.hotel.expanded.address) {
        hotelRequest.address = this.hotel.expanded.address.id
      }
      if (hotel.expanded.cuisineCategories) {
        hotelRequest.cuisineCategories = hotel.expanded.cuisineCategories.map(record => record.id)
      }
      delete hotelRequest.expanded
      return hotelRequest
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.saving = true
        this.$store.dispatch('hotel/create', this.prepareRequest(this.hotel))
          .then(() => {
            this.saving = false
            if (this.$store.getters['hotel/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.hotel = this.$store.getters['hotel/detail']
              this.$router.push('/articleTest/hotel/' + this.hotel.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['hotel/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    checkUsages () {
      this.$store.commit('hotel/setUsagesId', this.hotel.id)
      this.usages = { page: 1, totalCount: 0, data: [], showLoader: true }
      this.getUsagesList()
    },
    getUsagesList () {
      this.usages.showLoader = true
      this.$store.dispatch('hotel/fetchUsages')
        .then(() => {
          this.usages.data = this.$store.getters['hotel/usagesList']
          this.usages.totalCount = this.$store.getters['hotel/usagesTotalCount']
          this.usages.page = this.$store.getters['hotel/usagesPage']
          this.usages.showLoader = false
        })
    },
    setUsagesPageAndGetRecords (page) {
      this.$store.commit('hotel/setUsagesPage', page)
      this.getUsagesList()
    },
    deleteHotel () {
      if (this.usages.totalCount > 0) {
        NotifyService.setErrorMessage(this.$t('notify.record_not_deleted'))
        return
      }
      this.$store.dispatch('hotel/deleteRecord', this.hotel)
        .then(() => {
          if (this.$store.getters['hotel/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/articleTest/hotel')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['hotel/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.$store.commit('hotel/storeImage', null)
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
table {
  td {
    vertical-align: middle;

    &.unit {
      width: 15%;
      text-align: center;
      font-size: 0.85rem;
    }

    &:first-child {
      width: 30%;
    }

    input.form-control {
      text-align: right !important;
    }

    .form-group {
      margin-bottom: 5px;
    }
  }

  th {
    label:first-letter {
      text-transform: capitalize;
    }

    &:first-child {
      width: 30%;
    }
  }

  tr.button-align-center {
    text-align: center;
  }

  &.pros-cons {
    td {
      input.form-control {
        text-align: left !important;
      }
    }
  }
}
</style>

<style lang="scss">
table.test-hotel {
  td {
    input.form-control {
      text-align: right !important;
    }
  }

  &.pros-cons {
    td {
      input.form-control {
        text-align: left !important;
      }
    }
  }
}
</style>
